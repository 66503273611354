<section class="flex flex-col place-items-center place-content-center h-screen mx-2 lg:mx-0">
    <div class="px-8 border rounded-lg py-8 lg:py-24">
        <div class="mb-4">
            <img src="../../assets/svg/check.svg" alt="check icon" class="mx-auto" />
        </div>
        <div class="text-center">
            <p class="text-zestBlack text-sm lg:text-2xl font-semibold tracking-wider">Thank you for your order!</p>
            <p class="text-xs lg:text-base text-zestGrey pt-2 pb-8">Your payment has been processed successfully.</p>
        </div>
        <button (click)="goHome()" class="bg-zestGreen text-white rounded-lg py-4 px-20 w-full">Continue</button>
    </div>
</section>