import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../shared/services/snackbar.service';
import { StoreService } from '../shared/services/store.service';

@Component({
  selector: 'app-store-ratings',
  templateUrl: './store-ratings.component.html',
  styleUrls: ['./store-ratings.component.scss'],
})
export class StoreRatingsComponent implements OnInit {
  constructor(
    public store: StoreService,
    public snackBar: SnackBarService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.ratingValues = this.fb.group({
      item_quality: new FormControl('', Validators.required),
      seller_customer_exp: new FormControl('', Validators.required),
      overall_exp: new FormControl('', Validators.required),
      comment: new FormControl(''),
    });
  }

  singleStore!: {
    storename: string;
  };

  queryParams: any;

  pageCreation: boolean = false;

  ratingValues: FormGroup;

  token!: string;

  customerCode!: string;

  storeNotFound!: boolean;

  storeCode: string = '';
  storeResponse: any;

  currentStore: any;

  storeName: string = '';

  productFetched!: boolean;
  storeFetched: boolean = false;

  addReview(data: any) {
    this.store
      .submitRating(this.customerCode, this.storeCode, this.token, data)
      .subscribe({
        next: (res: any) => {
          this.pageCreation = false;
          this.snackBar.openSnackBar(`${res.message}`, 'success-snackbar');
          this.ratingValues.reset();
        },
        error: (err) => {
          this.pageCreation = false;
          this.snackBar.openSnackBar(`${err.error.message}`, 'error-snackbar');
          this.ratingValues.reset();
        },
      });
  }

  onSubmit() {
    this.pageCreation = true;
    this.addReview(this.ratingValues.value);
  }

  getStore(name: string) {
    this.store.findStoreByName(name).subscribe({
      next: (response: any) => {
        this.storeResponse = response;
        this.storeFetched = true;

        this.productFetched = false;
      },
      error: (err) => {
        this.storeNotFound = true;
        this.storeFetched = true;
        this.productFetched = true;
      },
    });
  }

  ngOnInit(): void {
    this.storeFetched = false;
    this.singleStore = {
      storename: this.route.snapshot.params['name'],
    };

    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      this.token = params.token;
      this.customerCode = params.customer_code;
      this.storeCode = params.store_code;
    });

    const name = this.singleStore.storename;

    this.getStore(name);
    const currentStore: any =
      JSON.parse(sessionStorage.getItem('currentStore')!) ?? null;

    window.scrollTo(0, 0);
  }
}
