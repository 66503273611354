<div
  *ngIf="rating.active"
  class="sm:pt-[56px] sm:pb-[62px] xl:pt-[120px] xl:pb-[142px] sm:block xl:flex lg:justify-between lg:items-start"
>
  <div class="grow flex text-left xl:block mb-12">
    <p class="font-semibold text-[32px] mr-3 xl:text-[56px] leading-[64px]">
      {{ rating.review ?? 0 }}
    </p>

    <div class="flex flex-col pl-3 lg:pl-0 text-sm lg:text-base">
      <div
        class="flex justify-center items-center py-[6.5px] lg:justify-start lg:py-[14.5px]"
      >
        <star-rating
          [readOnly]="true"
          [rating]="rating.review"
          starType="svg"
          [numOfStars]="5"
          Size="4"
          Color="ok"
        ></star-rating>
      </div>
      <small
        class="block pl-2 lg:pl-0 xl:hidden font-medium text-sm leading-4 text-zestLabel"
        >{{ this.total || 0 }} <span *ngIf="total === 1; else plural">rating</span
        ><ng-template #plural>ratings</ng-template></small
      >
    </div>

    <small class="hidden xl:block font-medium text-sm leading-4 text-zestLabel"
      >{{ this.total || 0 }} <span *ngIf="total === 1; else plural">rating</span
      ><ng-template #plural>ratings</ng-template></small
    >
  </div>
  <div class="grow ml-0 lg:ml-1 mb-12 xl:mb-0 xl:-ml-24">
    <div class="flex justify-start items-center pb-[22px]">
      <p class="font-semibold text-sm lg:text-base text-zestBlack flex">
        1 <span class="font-normal ml-1 mr-3">Star</span>
      </p>
      <div class="w-full h-[2px] bg-[#C1C7D0] mx-3"></div>
      <p class="font-normal text-base text-zestBlack">
        {{rating.review_breakdown?.['1_star'] || 0}}
      </p>
    </div>

    <div class="flex justify-start items-center pb-[22px]">
      <p class="font-semibold text-sm lg:text-base text-zestBlack flex">
        2 <span class="font-normal ml-1 mr-3" mr-1>Stars</span>
      </p>
      <div class="w-full h-[2px] bg-[#C1C7D0] mr-3"></div>
      <p class="font-normal text-base text-zestBlack">
        {{rating.review_breakdown?.['2_stars'] || 0}}
      </p>
    </div>

    <div class="flex justify-start items-center pb-[22px]">
      <p class="font-semibold text-sm lg:text-base text-zestBlack flex">
        3 <span class="font-normal ml-1 mr-3">Stars</span>
      </p>
      <div class="w-full h-[2px] bg-[#C1C7D0] mr-3"></div>
      <p class="font-normal text-base text-zestBlack">
        {{rating.review_breakdown?.['3_stars'] || 0}}
      </p>
    </div>

    <div class="flex justify-start items-center pb-[22px]">
      <p class="font-semibold text-sm lg:text-base text-zestBlack flex">
        4 <span class="font-normal ml-1 mr-3">Stars</span>
      </p>
      <div class="w-full h-[2px] bg-[#C1C7D0] mr-3"></div>
      <p class="font-normal text-base text-zestBlack">
        {{rating.review_breakdown?.['4_stars'] || 0}}
      </p>
    </div>

    <div class="flex justify-start items-center pb-[22px]">
      <p class="font-semibold text-sm lg:text-base text-zestBlack flex">
        5 <span class="font-normal ml-1 mr-3">Stars</span>
      </p>
      <div class="w-full h-[2px] bg-[#C1C7D0] mr-3"></div>
      <p class="font-normal text-base text-zestBlack">
        {{rating.review_breakdown?.['5_stars'] || 0}}
      </p>
    </div>
  </div>
  <div class="grow ml-0 xl:ml-24">
    <p class="text-zestBlack font-semibold text-base">Ratings Breakdown</p>

    <div class="flex justify-between items-start pt-3 pb-4">
      <p>Items Quality</p>
      <div class="flex justify-between items-center">
        <p class="pr-[18px]">
          {{ rating.review_breakdown?.items_quality || 0 }}
        </p>
        <star-rating
          [readOnly]="true"
          [rating]="1"
          starType="svg"
          [numOfStars]="1"
          Size="4"
          Color="ok"
        ></star-rating>
      </div>
    </div>

    <div class="flex justify-between items-start">
      <p>Delivery</p>
      <div class="flex justify-between items-center">
        <p class="pr-[18px]">
          {{ rating.review_breakdown?.delivery_quality || 0 }}
        </p>
        <star-rating
          [readOnly]="true"
          [rating]="1"
          starType="svg"
          [numOfStars]="1"
          Size="4"
          [ngStyle]="{ color: 'orange' }"
        ></star-rating>
      </div>
    </div>
  </div>
</div>
