import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-single-store-reviews',
  templateUrl: './single-store-reviews.component.html',
  styleUrls: ['./single-store-reviews.component.scss'],
})
export class SingleStoreReviewsComponent implements OnInit {
  constructor() {}

  @Input() rating: any;
  total: number = 0;

  ngOnInit(): void {
    if (this.rating?.review_breakdown) {
      this.total =
        this.rating?.review_breakdown?.['1_star'] +
        this.rating?.review_breakdown?.['2_stars'] +
        this.rating?.review_breakdown?.['3_stars'] +
        this.rating?.review_breakdown?.['4_stars'] +
        this.rating?.review_breakdown?.['5_stars'];
    }
  }
}
