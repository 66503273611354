import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { StoreService } from 'src/app/shared/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-details-header',
  templateUrl: './single-details-header.component.html',
  styleUrls: ['./single-details-header.component.scss'],
})
export class SingleDetailsHeaderComponent implements OnInit {
  bizName: string | any;
  @Input() storeName: any;
  displayName: any;
  setupStoreLink: string = '';
  active!: boolean;
  imageLink: string = '';
  constructor(public store: StoreService) {
    this.setupStoreLink = environment.SETUP_STORE_URL;
  }

  // showPicture() {
  //   this.store.getPicture(this.storeName.merchantId).subscribe({
  //     next: (res: any) => {
  //       this.imageLink = res.response.body;
  //       console.log(this.imageLink);
  //     },
  //   });
  // }

  showPicture() {
    this.store.getPicture(this.storeName).subscribe({
      next: (res: any) => {
        this.imageLink = res.response.body;
      },
    });
  }

  ngOnInit(): void {
    this.bizName = this.storeName?.slug;

    if (this.storeName) {
      this.active = this.storeName?.active;
      this.showPicture();
    }
  }

  ngAfterContentChecked(): void {
    this.displayName = this.storeName?.storeName;
    this.bizName = this.storeName?.slug;
  }
}
