import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../ui-elements/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })

export class SnackBarService {
		
	constructor(private _snackBar: MatSnackBar) { }

	duration = 4000

	openSnackBar(message: string, panelClass: string) {
		this._snackBar.openFromComponent(SnackBarComponent, {
			data: message,
			duration: this.duration,
			verticalPosition: 'top',
      		horizontalPosition: 'center',
			panelClass: [panelClass]
		});
	}
}