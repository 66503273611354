import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartCheckoutComponent } from './cart-checkout/cart-checkout.component';
import { ClientViewComponent } from './client-view/client-view.component';
import { SingleStoreProductListComponent } from './single-store-product-list/single-store-product-list.component';
import { SingleStoreSingleProductComponent } from './single-store-single-product/single-store-single-product.component';
import { StoreRatingsComponent } from './store-ratings/store-ratings.component';
import { SuccessComponent } from './success/success.component';

const routes: Routes = [
  {
    path: '',
    component: ClientViewComponent,
  },
  {
    path: 'stores/:name/cart',
    component: CartCheckoutComponent,
    title: 'Cart',
  },
  {
    path: 'stores/:name',
    component: SingleStoreProductListComponent,
  },
  {
    path: 'stores/:name/ratings',
    component: StoreRatingsComponent,
  },
  {
    path: 'stores/:name/payment-success',
    component: SuccessComponent,
  },
  {
    path: 'stores/:name/:id',
    component: SingleStoreSingleProductComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
