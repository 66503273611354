import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-store-footer',
  templateUrl: './single-store-footer.component.html',
  styleUrls: ['./single-store-footer.component.scss'],
})
export class SingleStoreFooterComponent implements OnInit {
  @Input() storeName: any;
  businessName: string | any;
  bizName: string | any;
  setupStoreLink!: string;

  constructor() {
    this.setupStoreLink = environment.SETUP_STORE_URL;
  }

  ngOnInit(): void {}
}
