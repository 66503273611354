<div *ngIf="storeName?.active" class="block lg:flex justify-between items-start py-12">
  <div>
    <p class="text-zestBlack text-base tracking-wide font-bold">
      {{ storeName?.storeName }}
    </p>
    <p class="text-zestLabel text-base tracking-wide pt-2 pb-8">
      BY {{ storeName?.business_name }}
    </p>
    <div
      *ngIf="storeName?.phone_number"
      class="pt-3 flex justify-start items-center lg:pt-5 pb-4"
    >
      <img
        class="pr-4 w-8"
        src="../../../assets/svg/Call.svg"
        alt="telephone"
      />
      <p class="text-zestBlack tracking-wider">
        {{ storeName?.phone_number }}
      </p>
    </div>
    <div class="flex justify-start items-center">
      <img class="pr-4 w-8" src="../../../assets/svg/Message.svg" alt="email" />
      <p class="text-zestBlack tracking-wider">
        {{ storeName?.email || storeName?.merchant_email | lowercase }}
      </p>
    </div>
  </div>
  <div class="hidden lg:flex mt-10 items-center">
    <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
    <small class="block text-zestLabel font-semibold text-sm"
      >Secured By
      <a
        href="{{ setupStoreLink }}"
        target="_blank"
        rel="noopener"
        class="text-zestGreen"
        >Zest</a
      ></small
    >
  </div>
  <div class="flex lg:hidden mt-10 items-center justify-center">
    <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
    <small class="block text-zestLabel font-semibold text-sm"
      >Secured By
      <a
        href="{{ setupStoreLink }}"
        target="_blank"
        rel="noopener"
        class="text-zestGreen"
        >Zest</a
      ></small
    >
  </div>
</div>
