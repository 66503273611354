import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit, AfterContentChecked, Inject } from '@angular/core';
import { StoreService } from 'src/app/shared/services/store.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-single-store-checkout-modal',
  templateUrl: './single-store-checkout-modal.component.html',
  styleUrls: ['./single-store-checkout-modal.component.scss'],
})
export class SingleStoreCheckoutModalComponent
  implements OnInit, AfterContentChecked
{
  storeurl: string = '';
  total: number = 0;
  count: number = JSON.parse(localStorage.getItem('cartCount')!);
  cart: any[] = JSON.parse(localStorage.getItem('cart')!);
  warning: boolean = false;

  copy: boolean = false;
  delivery: string = '';
  states: any[] = [];
  cities: string[] = [];
  deliveryCharge: any;

  constructor(
    public store: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SingleStoreCheckoutModalComponent>,
    public http: HttpClient,
    public fb: FormBuilder
  ) {
    this.http.get<any[]>('./assets/shipping.json').subscribe((data) => {
      this.states = data;
    });
  }

  checkoutInfo: FormGroup = this.fb.group({
    addressDTO: this.fb.group({
      last_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      first_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      country: new FormControl('NG', Validators.required),
      state: new FormControl('Select a state', Validators.required),
      city: new FormControl('', [Validators.required]),
      line1: new FormControl('', [
        Validators.required,
        Validators.maxLength(45),
      ]),
      metadata: this.fb.group({
        delivery_note: new FormControl(''),
      }),
    }),
    // checkoutCode: new FormControl(''),
    // countryCode: new FormControl('+234', Validators.required),
  });

  countries = ['Nigeria'];

  codes = ['+234'];

  regions = [
    'South West',
    'South East',
    'South South',
    'North West',
    'North East',
    'North Central',
  ];

  stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  confirm(order: any) {
    this.dialogRef.close({ orderDetail: order });
  }

  deliveryFee() {
    const delivery = this.checkoutInfo.get('addressDTO')?.get('region')?.value;
    if (this.data?.region) {
      for (let location of this.data.region) {
        if (delivery === location.area) {
          this.deliveryCharge = location.fee;
        }
      }
    }
  }

  stateChanged() {
    const state = this.checkoutInfo.get('addressDTO')?.get('state')?.value;

    const selectedState = this.states.find((s) => s.name === state);

    if (selectedState) {
      this.cities = selectedState.cities;
      this.checkoutInfo.patchValue({ addressDTO: { city: '' } });
    }
  }

  onSubmit(e: any) {
    e.preventDefault();
    const nestedForm = this.checkoutInfo
      .get('addressDTO')
      ?.get('metadata') as FormGroup;
    nestedForm.addControl(
      'delivery_fee',
      new FormControl(this.deliveryCharge ?? null)
    );
    const newValue = '+234';
    const currentValue = this.checkoutInfo
      .get('addressDTO')
      ?.get('phone')?.value;
    this.checkoutInfo
      .get('addressDTO')
      ?.get('phone')
      ?.setValue(newValue + currentValue);

    let formValues = { ...this.data.order, ...this.checkoutInfo.value };

    this.confirm(formValues);
  }

  ngOnInit(): void {
    if (this.data.region) {
      let newControl = this.checkoutInfo.get('addressDTO') as FormGroup;
      newControl?.addControl(
        'region',
        new FormControl('', Validators.required)
      );
    } else if (this.data.store?.shipping === 'PICKUP_IN_STORE') {
      let getControl = this.checkoutInfo.get('addressDTO') as FormGroup;
      getControl?.get('country')?.clearValidators();
      getControl?.get('state')?.clearValidators();
      getControl?.get('city')?.clearValidators();
      getControl?.get('line1')?.clearValidators();
      getControl?.get('line2')?.clearValidators();
    } else return;
  }

  ngAfterContentChecked(): void {
    this.deliveryFee();
    this.total = JSON.parse(localStorage.getItem('cartTotal')!);
  }
}
