import { Pipe, PipeTransform } from '@angular/core';

/**
 * Created by Stephen on 06/09/2022.
 */

@Pipe({
	name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
	transform(value: string, limit = 25, ellipsis = '...') {
		if (!value || value.length < limit) {
			return value;
		}
		return `${value.substring(0, limit)}${ellipsis}`
	};
}
