<header
  class="px-4 flex justify-between sticky top-0 z-10 w-full bg-white shadow-sm items-center lg:px-40 py-4 h-20"
>
  <div
    class="flex items-center cursor-pointer"
    [routerLink]="['/stores', bizName]"
  >
    <ngx-avatars
      [name]="displayName"
      initialsSize="0"
      cornerRadius="4"
      [round]="false"
      bgColor="#0D0F11"
      fgColor="#ffffff"
      size="40"
    >
    </ngx-avatars>
    <p
      class="pl-[10px] text-sm leading-4 text-zestBlack font-semibold lg:text-xl lg:leading-8 font-mont"
    >
      {{ displayName }}
    </p>
  </div>
  <div *ngIf="storeName?.active"
    class="flex flex-col-reverse items-end md:flex md:flex-row md:items-center"
  >
   
    <div
      class="flex items-center pl-5"
      [routerLink]="['/stores', bizName, 'cart']"
    >
      <img src="../../../assets/svg/Buy.svg" alt="cart" />
      <p
        class="block text-xs lg:hidden pl-[6px] text-zestWhite bg-zestGreen rounded py-[2px] px-2"
      >
        {{ store.getCartLenght() }}
      </p>
      <p
        class="px-2 font-mont text-zestBlack font-medium text-sm leading-6 cursor-pointer hidden lg:block"
      >
        My Cart
        <span class="pl-[6px] text-zestWhite bg-zestGreen rounded py-1 px-2">{{
          store.getCartLenght()
        }}</span>
      </p>
    </div>
  </div>
</header>
