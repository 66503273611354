import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss'],
})
export class ClientViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // window.location.href = `${environment.SETUP_STORE_URL}`;
  }
}
