import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit, OnChanges {
  index!: number;
  @Input() total!: number ;
  mode: ProgressSpinnerMode = 'determinate';
  @Input() value!: number;
  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    const calcIndex = (this.total/100)*this.value;
    
    this.index =  Math.round(calcIndex)
  }

  ngOnInit(): void {
  }



}
