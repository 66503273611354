<app-loading-spinner *ngIf="productFetched"></app-loading-spinner>
<section class="font-mont bg-white lg:bg-[#FBFBFB]" appScrollToTop>
  <app-single-details-header
    [storeName]="productDetails[0]?.product.store"
  ></app-single-details-header>

  <div class="mx-0 px-5 mt-8 flex items-center lg:px-0 lg:mx-40">
    <img
      src="../../../assets/svg/back.svg"
      alt="back"
      class="cursor-pointer"
      (click)="onBackClick()"
    />
    <p class="hidden lg:block font-medium text-zestBlack text-lg pl-4">Back</p>
  </div>

  <main class="bg-white py-4 my-4 lg:my-6 mx-6 rounded lg:mx-40 lg:p-8">
    <!-- Todo: Put this in a component of it's own -->
    <div
      class="block xl:flex items-start"
      *ngFor="let productDetail of productDetails; let i = index"
    >
      <div class="relative flex flex-col-reverse justify-center items-center xl:hidden w-full xl:items-start">
        <div class="flex justify-center mt-3">
          <img
            *ngFor="
              let image of productDetail?.product.image_data;
              let i = index; let last = last
            "
            [ngClass]="{
              'border-zestBlack': inview[i] === true,
              'border-zestBg': inview[i] === false,
              'mr-0': last
            }"
            class="w-[104px] h-[88px] border rounded mb-4 cursor-pointer mr-3 p-2 object-contain"
            src="{{ image.image_source }}"
            (click)="owlCar.to(image.image_code)"
            alt="single"
          />
        </div>
        <div
          class="w-full flex justify-center items-center mx-auto rounded backdrop-blur-sm my-auto"
        >
          <owl-carousel-o
            #owlCar
            [options]="carouselOptions"
            class="w-6/12 relative"
            (translated)="onSlideChange($event)"
          >
            <ng-container
              *ngFor="let image of productDetail?.product.image_data"
            >
              <ng-template
                carouselSlide
                class="carousel-item"
                [id]="image.image_code"
              >
                <img
                  [src]="image.image_source"
                  alt="Carousel Image"
                  class="rounded mx-auto h-full object-contain"
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
      <div class="flex grow">
        <div class="hidden xl:flex items-start xl:w-7/12 grow">
          <div class="w-1/5 flex xl:flex-col justify-center mr-4 xl:mr-0">
            <img
              *ngFor="
                let image of productDetail?.product.image_data;
                let i = index
              "
              class="w-20 border rounded mb-6 cursor-pointer p-1"
              [ngClass]="{
                'border-zestBlack': inview[i] === true,
                'border-zestBg': inview[i] === false
              }"
              src="{{ image.image_source }}"
              (click)="owlCar2.to(image.image_code)"
              alt="single"
            />
          </div>
          <div
            class="hidden xl:flex justify-center h-full xl:h-[500px] xl:mx-auto items-start xl:w-[400px] rounded"
            #containerImage
          >
            <owl-carousel-o
              #owlCar2
              [options]="carouselOptions"
              class="w-3/4 relative"
              (translated)="onSlideChange($event)"
            >
              <ng-container
                *ngFor="let image of productDetail?.product.image_data"
              >
                <ng-template
                  carouselSlide
                  class="carousel-item"
                  [id]="image.image_code"
                >
                  <img
                    [src]="image.image_source"
                    alt="Carousel Image"
                    class="rounded mx-auto h-full object-contain"
                  />
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>


        </div>
        <div class="grow ml-0 mr-0 mt-10 xl:mt-0 xl:mr-0 xl:ml-20 xl:w-5/12">
          <div class="flex flex-col justify-between items-start lg:block pb-3">
            <p
              class="font-normal lg:font-medium pr-2 text-base lg:pr-0 lg:text-2xl mb-4 text-zestBlack"
            >
              {{ productDetail?.product.product_name }}

            </p>

            <p class="text-xs lg:text-sm">Product code: <strong>{{productDetail?.product.product_code}}</strong> <span
              *ngIf="available"
              class="ml-2 text-zestGreen text-sm bg-[#ECFEF0] px-3 rounded py-2"
              >In stock</span
            >
            <span
              *ngIf="!available"
              class="ml-2 text-red-500 text-xs lg:text-sm bg-[#feecec] px-3 rounded py-2"
              >Out of stock</span
            ></p>
          </div>
          <hr class="block border-[#C1C7D0]" />
          <div class="flex justify-start items-center mt-4 lg:mt-0 lg:block">
            <div class="flex items-start">
              <p
                *ngIf="productDetail?.variants.length > 0; else price"
                class="pt-0 pb-0 lg:pt-6 lg:pb-4 pr-4 lg:pr-0 text-zestBlack text-base lg:text-2xl font-bold"
              >
                {{ selectedPrice | currency : "₦ " }}
                <span
                  *ngIf="
                    selectedPrice &&
                    selectedPrice < productDetail?.product.price
                  "
                  class="text-base text-zestLabel line-through font-medium block lg:pt-2"
                  >{{ productDetail?.product.price | currency : "₦ " }}</span
                >
              </p>
              <ng-template #price>
                <p
                  *ngIf="
                    productDetail?.product.discount;
                    else showPrice
                  "
                  class="pb-0 text-base lg:text-2xl font-bold text-zestBlack lg:pb-4 lg:pt-6"
                >
                  {{
                    productDetail?.product.discounted_price | currency : "₦ "
                  }}
                  <span
                    *ngIf="
                      productDetail?.product.discounted_price <
                      productDetail?.product.price
                    "
                    class="text-xs text-zestLabel line-through font-medium block lg:pt-2"
                    >{{ productDetail?.product.price | currency : "₦ " }}</span
                  >
                </p>
                <ng-template #showPrice>
                  <p
                    class="pb-0 text-base lg:text-2xl font-bold text-zestBlack lg:pb-4 lg:pt-6"
                  >
                    {{ (productDetail?.product.price | currency : "₦ ") }}
                  </p>
                </ng-template>
              </ng-template>
              <p
                *ngIf="
                  selectedPrice &&
                  percent > 0 &&
                  selectedPrice < productDetail.product.price
                "
                class="flex bg-zestGreen text-base text-zestWhite font-medium p-1 ml-4 rounded mt-0 pb-1 lg:mt-6"
              >
                {{ discountPercent }}
              </p>
              <p
                *ngIf="
                  productDetail.variants.length === 0 &&
                  productDetail.product.discount
                "
                class="flex bg-zestGreen text-xs lg:text-sm text-zestWhite font-medium p-1 ml-4 rounded mt-0 pb-1 lg:mt-6"
              >
                -{{ productDetail.product.discount }}
                %
              </p>
            </div>
          </div>

          <!--Start of quantity button for products with variants -->
          <div
            class="block"
            *ngIf="productDetail?.product.productVariant"
          >
            <p class="pr-0 pb-2 mb-2 mt-4 lg:pr-20 lg:pb-0 text-zestGrey">Quantity</p>
            <div class="inline-flex items-center p-1.5 gap-[3px] rounded-[4px] border border-ZTN-300">
              <button
              [disabled]="variantOutOfStock"
                (click)="minus()"
                class="w-8 h-8 bg-zestNeutral rounded-[2px] focus:border-zestGreen focus:ring-zestGreen text-xxs disabled:text-ZTN-300"
              >
                -
              </button>
              <p class="px-2 min-w-[18px] text-[14px] text-[#667085]">
                {{ count }}
              </p>
              <button
                [disabled]="variantOutOfStock || quantityOutOfStock"
                (click)="plus(productDetail)"
                class="w-8 h-8 bg-zestNeutral rounded-[2px] focus:border-zestGreen focus:ring-zestGreen text-xxs disabled:text-ZTN-300"
              >
                +
              </button>
            </div>
          </div>
          <!-- End of quantity button for products with variants -->

          <!-- Start of quantity button for products without variants -->
          <div
            class="block"
            *ngIf="!productDetail?.product.productVariant"
          >
            <p class="pr-0 pb-2 mb-2 mt-4 lg:pr-20 lg:pb-0 text-zestGrey">Quantity</p>
            <div class="inline-flex items-center p-1.5 gap-[3px] rounded-[4px] border border-ZTN-300">
              <button
                (click)="minus()"
                [ngClass]="{
                  'focus-outline-none bg-gray-100':
                    store.getItemCount(productDetail?.product.product_code) ===
                    0
                }"
                [disabled]="
                  store.getItemCount(productDetail?.product.product_code) === 0
                "
                class="w-8 h-8 bg-zestNeutral rounded-[2px] focus:border-zestGreen focus:ring-zestGreen text-xxs disabled:text-ZTN-300"
              >
                -
              </button>
              <p class="text-[14px] text-[#667085]">
                {{ count }}
              </p>
              <button
              [disabled]="quantityOutOfStock"
                (click)="plus(productDetail)"
                class="w-8 h-8 bg-zestNeutral rounded-[2px] focus:border-zestGreen focus:ring-zestGreen text-xxs disabled:text-ZTN-300"
              >
                +
              </button>
            </div>

          </div>
          <div *ngIf="quantityOutOfStock" class="h-2  my-2 text-14 font-medium leading-4 tracking-0.14 text-[#F79009]">
            Only {{variantStock > 0 ? variantStock : productDetail?.product?.quantity_in_stock}} item(s) left
          </div>
          <!-- End of quantity button for products without variants -->
          <div
            *ngIf="productDetail?.product.productVariant"
            class="pb-2 block w-full lg:flex justify-start items-start md:w-3/5 lg:flex-wrap xl:w-full"
          >
            <div *ngFor="let options of productDetail?.meta; let i = index">
              <div>
                <div>
                  <label
                    class="block text-sm tracking-[0.01em] text-zestLabel pb-2 pt-2"
                    >{{ options.type }}</label
                  >
                  <select
                    (ngModelChange)="selectedVariant($event, i)"
                    [(ngModel)]="optionSelected[i]"
                    class="px-3 py-3 mr-3 border-[#C1C7D0] border rounded focus:border-zestGreen focus:ring-zestGreen w-full lg:w-[200px]"
                  >
                    <option
                      [value]="option"
                      class="text-zestPlaceholder"
                      *ngFor="let option of options.values"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full md:w-3/5 xl:w-full" *ngIf="!variantOutOfStock; else outOfStock">
            <div
              class="flex items-center justify-center bg-[#F5F7FA] rounded-lg py-4 px-6 mt-3 w-full mr-[5px]"
            >
              <img src="../../../assets/svg/Buy.svg" alt="cart" />
              <button
                (click)="
                  productDetail?.product.productVariant
                    ? variantCart(
                        productDetail,
                        count,
                        selectedNameVariant,
                        selectedPrice,
                        productVariant
                      )
                    : cart()
                "
                class="text-zestBlack font-semibold pl-3 text-base"
              >
                Add to cart
              </button>
            </div>

            <button
              (click)="
                productDetail?.product.productVariant
                  ? variantBuyNow(
                      productDetail,
                      selectedNameVariant,
                      selectedPrice,
                      productVariant
                    )
                  : buyNow()
              "
              class="bg-zestBlack text-white rounded-lg py-4 px-6 mt-3 w-3/4 ml-[5px] text-base font-semibold"
            >
              Buy Now
            </button>
          </div>
          <ng-template #outOfStock>
            <div class="bg-ZTO-50 px-4 py-3 w-full md:w-3/5 xl:w-full flex justify-start items-center mt-4">
              <img src="../../assets/svg/infowarn.svg" alt="info" />
              <p class="pl-2 text-sm font-medium">Variant out of stock. Please select another option.</p>
            </div>
          </ng-template>

          <div class="py-6">
            <p class="text-zestBlack py-2 font-medium">Product Description</p>
            <p class="text-zestGrey text-sm">
              {{ productDetail?.product.product_description }}
            </p>
          </div>

          <hr class="border-[#C1C7D0]" />

          <div class="pt-6">
            <div>
              <p class="font-semibold text-zestBlack text-sm pb-2">Delivery:</p>
              <p
                class="font-normal text-sm leading-6 text-zestBlack"
                *ngIf="productDetail?.product.delivery"
              >
                Estimated between
                {{ productDetail?.product.delivery }} after making purchase.
              </p>
            </div>
            <div>
              <p class="font-semibold text-zestBlack text-sm pb-2 pt-3">
                Return Policy:
              </p>
              <p class="font-normal text-sm leading-6 text-zestBlack">
                {{ productDetail?.product.store?.return_policy }}
              </p>
            </div>
            <p class="font-semibold text-zestBlack text-sm pt-3">
              SHARE THIS PRODUCT
            </p>
            <div class="flex justify-start items-center py-4">
              <div class="mr-8">
                <button
                  shareButton="facebook"
                  class="w-8 h-8 cursor-pointer"
                  url="{{ link }}"
                  description="Check out this product in my store for just {{
                    productDetail.product.discounted_price ??
                      productDetail.product.price | currency : 'NGN '
                  }}"
                >
                  <img
                    src="../../../assets/svg/fb.svg"
                    alt="close"
                    class="w-8 h-8"
                  />
                </button>
              </div>
              <div class="mr-8">
                <button
                  shareButton="twitter"
                  url="{{ link }}"
                  description="Check out this product in my store for just {{
                    productDetail.product.discounted_price ??
                      productDetail.product.price | currency : 'NGN '
                  }}"
                  class="w-8 h-8 cursor-pointer"
                >
                  <img
                    src="../../../assets/svg/x.svg"
                    alt="close"
                    class="w-8 h-8"
                  />
                </button>
              </div>
              <div class="mr-8">
                <button
                  shareButton="whatsapp"
                  class="w-8 h-8 cursor-pointer"
                  url="{{ link }}"
                  description="Check out this product in my store for just {{
                    productDetail.product.discounted_price ??
                      productDetail.product.price | currency : 'NGN '
                  }}"
                >
                  <img
                    src="../../../assets/svg/whatsapp.svg"
                    alt="close"
                    class="w-8 h-8 cursor-pointer"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="my-8 px-4 lg:px-[154px] bg-[#F4F6F9]">
    <app-single-store-footer
      [storeName]="productDetails[0]?.product.store"
    ></app-single-store-footer>
  </div>
</section>
