import {
  AfterContentChecked,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { StoreService } from 'src/app/shared/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-store-header',
  templateUrl: './single-store-header.component.html',
  styleUrls: ['./single-store-header.component.scss'],
})
export class SingleStoreHeaderComponent implements OnInit, AfterContentChecked {
  bizName: string | any;
  @Input() storeName: any;
  showImage: boolean = false;

  @Output() searchTermChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterWithPrice: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:scroll', [])
  options: string[] = [
    'All categories',
    'High to Low price',
    'Low to High price',
  ];
  filter: any;
  displayName: any;
  setupStoreLink: string = '';
  active!: boolean;
  imageLink: string = '';
  bannerLink: string = '';
  constructor(public store: StoreService) {
    this.setupStoreLink = environment.SETUP_STORE_URL;
  }

  
  onSearchTermChange(event: any) {
    this.searchTermChanged.emit(event);
  }

  onFilterWithPrice(event: any) {
    this.filterWithPrice.emit(event);
  }
  // showPicture() {
  //   this.store.getPicture(this.storeName.merchantId).subscribe({
  //     next: (res: any) => {
  //       this.imageLink = res.response.body;
  //       console.log(this.imageLink);
  //     },
  //   });
  // }

  showPicture() {
    this.store.getPicture(this.storeName?.slug).subscribe({
      next: (res: any) => {
        const imageArray = res.imageData;
        imageArray.forEach((item: any) => {
          if (item.file_group === 'PROFILE_IMG') {
            this.imageLink = item.image_source;
          } else if (item.file_group === 'BANNER_IMG') {
            this.bannerLink = item.image_source;
          }
        });
      },
    });
  }

  openFilter() {
    this.filter = !this.filter;
  }
  ngOnInit(): void {
    this.bizName = this.storeName?.slug;
    if (this.storeName) {
      this.active = this.storeName?.active;
      this.showPicture();
    }
  }

  ngAfterContentChecked(): void {
    this.displayName = this.storeName?.storeName;
    this.bizName = this.storeName?.slug;
  }
}
