import { Component } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'zest';
  loadingRouteConfig: boolean = false;

  constructor(public router: Router) {}

  public loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.src = environment.SDK_URL;
    script.defer = true;

    /* add your script to DOM */
    body.appendChild(script);
  }

  ngOnInit() {
    // this.loadScript();
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }
}
