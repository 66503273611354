<mat-card>
    <mat-card-content>
    <h6 class="body-1">Step {{index}} of {{total}}</h6>
      <mat-spinner
          class="progress-margin"
          strokeWidth="6"
          diameter="70"
          [mode]="mode"
          [value]="value">
      </mat-spinner>
      <mat-spinner 
      class="track"
      strokeWidth="6"
      diameter="70"
      [mode]="mode"
      value="100">
    </mat-spinner>
    </mat-card-content>
  </mat-card>