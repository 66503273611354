import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../shared/services/store.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  constructor(
    private store: StoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  singleStore!: {
    storename: string;
  };

  pageCreation: boolean = false;

  storeNotFound!: boolean;

  storeCode: string = '';
  storeResponse: any;

  currentStore: any;

  productFetched!: boolean;
  storeFetched: boolean = false;

  goHome() {
    if (this.singleStore.storename) {
      this.router.navigate(['stores/', this.singleStore.storename]);
    } else return;
  }

  // Add this function in the store service
  getStore(name: string) {
    this.store.findStoreByName(name).subscribe({
      next: (response: any) => {
        this.storeResponse = response;
        this.storeFetched = true;

        this.storeCode = this.storeResponse.store_code;

        this.productFetched = false;
      },
      error: (err) => {
        this.storeNotFound = true;
        this.storeFetched = true;
        this.productFetched = true;
      },
    });
  }

  ngOnInit(): void {
    this.storeFetched = false;
    this.singleStore = {
      storename: this.route.snapshot.params['name'],
    };

    const name = this.singleStore.storename;


    this.getStore(name);
    const currentStore: any =
      JSON.parse(sessionStorage.getItem('currentStore')!) ?? null;

    window.scrollTo(0, 0);
  }
}
