import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';

/**
 * Created by Stephen on 04/08/2022.
 */

@NgModule({
  declarations: [TruncatePipe],
  exports: [TruncatePipe],
})
export class PipesModule {}
