import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterContentChecked,
  Component,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from '../shared/services/store.service';
import { SingleStoreCheckoutModalComponent } from '../single-store-checkout-modal/single-store-checkout-modal.component';

@Component({
  selector: 'app-single-mobile-checkout',
  templateUrl: './single-mobile-checkout.component.html',
  styleUrls: ['./single-mobile-checkout.component.scss'],
})
export class SingleMobileCheckoutComponent
  implements OnInit, AfterContentChecked
{
  storeurl: string = '';
  total: number = 0;
  count: number = JSON.parse(localStorage.getItem('cartCount')!);
  cart: any[] = JSON.parse(localStorage.getItem('cart')!);
  warning: boolean = false;

  copy: boolean = false;
  delivery: string = '';
  states: any[] = [];
  cities: string[] = [];
  deliveryChargeMob: any;
  showValidator!: boolean;

  @Input() data: any;
  @Output() updatedFormValues: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public store: StoreService,
    public http: HttpClient,
    public fb: FormBuilder,
    public location: Location
  ) {
    this.http.get<any[]>('./assets/shipping.json').subscribe((data) => {
      this.states = data;
    });
  }

  onBackClick() {
    this.store.showMobileCheckout();
  }

  checkoutInfo: FormGroup = this.fb.group({
    addressDTO: this.fb.group({
      last_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      first_name: new FormControl('', [
        Validators.required,
        Validators.pattern("^[a-zA-Z-' ]+$"),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      country: new FormControl('NG', Validators.required),
      state: new FormControl('Select a state', Validators.required),
      city: new FormControl('', Validators.required),
      line1: new FormControl('', [
        Validators.required,
        Validators.maxLength(45),
      ]),
      metadata: this.fb.group({
        delivery_note: new FormControl(''),
      }),
    }),
    // checkoutCode: new FormControl(''),
    // countryCode: new FormControl('+234', Validators.required),
  });

  countries = ['Nigeria'];

  codes = ['+234'];

  regions = [
    'South West',
    'South East',
    'South South',
    'North West',
    'North East',
    'North Central',
  ];

  stopPropagation($event: Event) {
    $event.stopPropagation();
  }

  confirm(order: any) {
    // this.dialogRef.close({ orderDetail: order });
  }

  deliveryFee() {
    const delivery = this.checkoutInfo.get('addressDTO')?.get('region')?.value;

    if (this.data && this.data?.region) {
      for (let location of this.data.region) {
        if (delivery === location.area) {
          this.deliveryChargeMob = location.fee;
        }
      }
    }
  }

  stateChanged() {
    const state = this.checkoutInfo.get('addressDTO')?.get('state')?.value;

    const selectedState = this.states.find((s) => s.name === state);

    if (selectedState) {
      this.cities = selectedState.cities;
      this.checkoutInfo.patchValue({ addressDTO: { city: '' } });
    }
  }

  onSubmit(e: any) {
    e.preventDefault();
    const nestedForm = this.checkoutInfo
      .get('addressDTO')
      ?.get('metadata') as FormGroup;
    nestedForm.addControl(
      'delivery_fee',
      new FormControl(this.deliveryChargeMob ?? null)
    );
    const newValue = '+234';
    const currentValue = this.checkoutInfo
      .get('addressDTO')
      ?.get('phone')?.value;
    this.checkoutInfo
      .get('addressDTO')
      ?.get('phone')
      ?.setValue(newValue + currentValue);

    let formValues = { ...this.data.order, ...this.checkoutInfo.value };

    this.updatedFormValues.emit(formValues);

    this.checkoutInfo.reset();
    this.store.showMobileCheckout();
  }

  isMobile() {
    this.total = JSON.parse(localStorage.getItem('cartTotal')!);
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    if (this.data?.region) {
      let newControl = this.checkoutInfo.get('addressDTO') as FormGroup;
      newControl?.addControl(
        'region',
        new FormControl('', Validators.required)
      );
      this.deliveryFee();
      this.total = JSON.parse(localStorage.getItem('cartTotal')!);
    }

    if (
      this.data?.store?.shipping === 'PICKUP_IN_STORE' &&
      this.store.isMobileShown()
    ) {
      let getControl = this.checkoutInfo.get('addressDTO') as FormGroup;
      getControl?.get('country')?.clearValidators();
      getControl?.get('state')?.clearValidators();
      getControl?.get('city')?.clearValidators();
      getControl?.get('line1')?.clearValidators();

      getControl?.get('country')?.updateValueAndValidity();
      getControl?.get('state')?.updateValueAndValidity();
      getControl?.get('city')?.updateValueAndValidity();
      getControl?.get('line1')?.updateValueAndValidity();

      this.total = JSON.parse(localStorage.getItem('cartTotal')!);
    } else {
      this.total = JSON.parse(localStorage.getItem('cartTotal')!);
    }

    this.isMobile();
  }
}
