<section class="Ratings lg:px-[492px] flex-col bg-white justify-center items-center block py-8 md:flex">
    <div class="AutoLayoutVertical flex-col justify-start items-start inline-flex">
        <div
            class="AutoLayoutVertical px-8 py-6 bg-gray-50 rounded-tl rounded-tr border border-gray-300 flex-col justify-start items-start gap-2.5 flex">
            <div class="AutoLayoutVertical flex-col justify-start items-start gap-2 flex">
                <div class="RateYourExperience w-[392px] text-gray-900 text-[20px] font-semibold leading-normal">Rate
                    your experience.</div>
                <div
                    class="HelpUsServeYouBetter w-[392px] opacity-80 text-gray-500 text-base font-normal leading-tight">
                    Help us serve you better.</div>
            </div>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="ratingValues"
            class="AutoLayoutHorizontal p-8 bg-white rounded-bl rounded-br border border-gray-300 justify-start items-start gap-2.5 inline-flex">
            <div class="AutoLayoutVertical flex-col justify-start items-end gap-6 inline-flex">
                <div class="AutoLayoutVertical flex-col justify-start items-start gap-8 flex">
                    <div class="AutoLayoutVertical flex-col justify-start items-start gap-8 flex">
                        <div class="AutoLayoutVertical flex-col justify-center items-center gap-[42px] flex">
                            <div class="AutoLayoutVertical flex-col justify-start items-start gap-3.5 flex">
                                <div
                                    class="RateTheItemQuality w-[392px] text-gray-500 text-base font-medium leading-normal">
                                    Rate the item quality
                                </div>
                                <div class="Group h-[54px]">

                                    <div class="AutoLayoutHorizontal items-center gap-8 inline-flex">

                                        <star-rating-control [readOnly]="false" starType="svg" [numOfStars]="5"
                                            size="large" space="around" formControlName="item_quality"
                                            color="ok"></star-rating-control>

                                    </div>
                                    <div
                                        class="TapTheStarToRate mt-3 w-[392px] opacity-80 text-gray-400 text-[14px] font-normal leading-none">
                                        Tap the star to rate
                                    </div>
                                </div>
                            </div>
                            <div class="AutoLayoutVertical flex-col justify-start items-start gap-3.5 flex">
                                <div
                                    class="RateTheDeliveryProcess w-[392px] text-gray-500 text-base font-medium leading-normal">
                                    Rate the seller customer service</div>
                                <div class="Group h-[54px]">
                                    <div class="AutoLayoutHorizontal items-center gap-8 inline-flex">

                                        <star-rating-control [readOnly]="false" starType="svg" [numOfStars]="5"
                                            size="large" space="around" formControlName="seller_customer_exp"
                                            color="ok"></star-rating-control>

                                    </div>
                                    <div
                                        class="TapTheStarToRate mt-3 w-[392px] opacity-80 text-gray-400 text-[14px] font-normal leading-none">
                                        Tap the star to rate</div>
                                </div>
                            </div>
                            <div class="AutoLayoutVertical flex-col justify-start items-start gap-3.5 flex">
                                <div
                                    class="RateTheItemQuality w-[392px] text-gray-500 text-base font-medium leading-normal">
                                    Rate the overall experience
                                </div>
                                <div class="Group h-[54px]">

                                    <div class="AutoLayoutHorizontal items-center gap-8 inline-flex">

                                        <star-rating-control [readOnly]="false" starType="svg" [numOfStars]="5"
                                            size="large" space="around" formControlName="overall_exp"
                                            color="ok"></star-rating-control>

                                    </div>
                                    <div
                                        class="TapTheStarToRate mt-3 w-[392px] opacity-80 text-gray-400 text-[14px] font-normal leading-none">
                                        Tap the star to rate
                                    </div>
                                </div>
                            </div>
                            <div class="AutoLayoutVertical flex-col justify-start items-start gap-3.5 flex">
                                <div
                                    class="RateTheItemQuality w-[392px] text-gray-500 text-base font-medium leading-normal">
                                    Comment
                                </div>
                                <div class="w-full">
                                    <div class="AutoLayoutHorizontal items-center">

                                        <textarea
                                            class="bg-white w-full outline-hidden resize-none placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-4 focus:border-gray-700 focus:ring-gray-700"
                                            type="text" rows="3" placeholder="Type Here" name="product_description"
                                            formControlName="comment"></textarea>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" [disabled]="ratingValues.invalid"
                            class="AutoLayoutHorizontal w-[392px] px-[173px] cursor-pointer py-3 bg-zestBlack hover:bg-gray-800 rounded-lg justify-center items-center gap-2 inline-flex disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-zestBlack
                            ">
                            <div
                                class="Submit text-center text-white text-[18px] font-medium leading-normal">
                                <mat-spinner [diameter]="20" class="w-full mx-auto"
                                    *ngIf="pageCreation; else name"></mat-spinner>
                                <ng-template #name>
                                    <p class="disabled:cursor-not-allowed">Submit</p>
                                </ng-template>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>